@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  body {
    @apply font-body;
    @apply text-cello;
    @apply leading-tight;
    font-size: 26px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  h1,
  h2,
  h3,
  h4 {
    @apply font-headings;
  }
}

@font-face {
  font-family: 'Ambit Regular';
  src: url('../fonts/ambit-regular.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Ambit Bold';
  src: url('../fonts/ambit-bold.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Ambit Black';
  src: url('../fonts/ambit-black.woff2') format('woff2');
  font-style: normal;
  font-weight: normal;
}

.bg-waves {
  background-image: url('../assets/images/waves.jpg');
}
